import React, { useContext } from 'react';

import { FormContext } from './context';

import './style.css';

import loadComponents from '../Loadable';

const H4 = loadComponents('h4');
const Fields = loadComponents('form-fields');
const Alerts = loadComponents('form-alerts');

export default function FormLayout({ name, fields, style }) {
  const form = useContext(FormContext);
  return (
    <div
      style={{
        position: `relative`,
        ...style,
      }}
    >
      <Alerts />
      <form
        name={name.replace(/\s+/g, '_').toLowerCase()}
        onSubmit={form.handleSubmit}
        className={`form${form.sent ? ` sent` : ``}`}
      >
        <input
          type="hidden"
          name="bot-field"
          id="bot-field"
          onChange={form.handleChange}
        />
        {fields.map((group, index) => (
          <div
            className="group"
            key={group.title}
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              margin: `0 -0.625rem`,
              padding: index === 0 && 0,
            }}
          >
            {group.title && (
              <div
                className="header"
                style={{
                  display: `flex`,
                  alignItems: `center`,
                  flex: `0 0 100%`,
                  maxWidth: `100%,`,
                  padding: `0 0.625rem`,
                }}
              >
                <H4 size="36px">{group.title}</H4>
              </div>
            )}
            <Fields data={group.fields} />
          </div>
        ))}
      </form>
    </div>
  );
}
